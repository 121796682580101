import React, { useEffect, useState, useRef } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import DataTable from "react-data-table-component";
// img
import AOS from "aos";
import "aos/dist/aos.css";
import down from "../assets/images/down.svg";
import calender from "../assets/images/calender.svg";
// import profit from "../assets/images/profit.svg";
// import tot_order from "../assets/images/tot_order.svg";
// import revenue from "../assets/images/revenue.svg";
// import new_products from "../assets/images/new_products.svg";

import profit from "../assets/images/1-removebg-preview.png";
import tot_order from "../assets/images/msg1176172984-45851-removebg-preview.png";
import revenue from "../assets/images/download (1).png";
import new_products from "../assets/images/msg1176172984-45850-removebg-preview.png";

import basket from "../assets/images/basket.svg";
import tag from "../assets/images/tag.svg";
import Sidebar from "../components/sidebar.js";
import MyChart from "../components/linechart.js";
import MyLineChart from "../components/linechart.js";
import Navbarinner from "../components/Navbarinner.js";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  Updatepayment,
  getdashBoardOrder,
  withDrawRequest,
  shopMaintenance,
} from "../action/user.action.js";
import { getUserStore } from "../redux/features/userstore/userStoreSlice";
import { useDispatch, useSelector } from "react-redux";
import { getUserProfile } from "../redux/features/user/userslice.js";
import moment from "moment";
import { toastAlert } from "../utils/toast.js";
import { Dropdown } from "react-bootstrap";
import ScrollToTopOnMount from "../components/ScrollToTopOnMount";
import MapChart from "./../components/globlemap/MapDisplay";
import { useTranslation } from "react-i18next";
import { getUserWallet } from "../redux/features/wallet/walletslice";

export default function Dashboard() {
  const dispatch = useDispatch();
  const chartRef = useRef(null);
  const userstore = useSelector((state) => state.userstore);
  const myproduct = useSelector((state) => state?.myproduct);

  // console.log(myproduct, 'myproduct');

  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  const [data, setDate] = useState();
  const [drpdwn, setdrpdwn] = useState();
  const [totalOrder, setTotalorder] = useState(0);
  const [reviewProfit, setreviewnewProfit] = useState(0);
  const [newProduct, setNewProduct] = useState(0);
  const [graphValue, setGraphvalue] = useState({});
  const [topSell, setTopSell] = useState([]);
  const history = useNavigate();
  const user = useSelector((state) => state.account);
  const [selectedValue, setSelectedValue] = useState("all");
  const [storeList, setStoreList] = useState([]);
  const [client_Secret, setclient_Secret] = useState();
  const [type, setType] = useState("l10");
  const [select, setselect] = useState("all");
  const [dailyProfit, setDailyProfit] = useState();
  const [maintenance, setmaintenance] = useState([]);

  const { t } = useTranslation();

  const handlePeriodChange = (type) => {
    chartRef.current.setPeriod(type);
    setType(type);
  };

  const handleselectChange = (type) => {
    chartRef.current.setPeriod(selectedValue);
  };

  const CheckClientData = async () => {
    try {
      const clientSecret = new URLSearchParams(window.location.search).get(
        "payment_intent"
      );
      // console.log('payment_intent', clientSecret);

      if (clientSecret) {
        const reqdata = {
          id: clientSecret,
        };
        const data = await Updatepayment(reqdata);

        // console.log(data, 'data');

        if (data.status) {
          dispatch(getUserProfile());
          dispatch(getUserWallet());
          setTimeout(() => {
            toastAlert("success", t("DEPOSIT_SUCCESS"));
          }, 500);

          setTimeout(() => {
            history("/dashboard");
            // window.location.reload()
          }, 1000);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    CheckClientData();
    getshopMaintenance();
  }, []);

  useEffect(() => {
    AOS.init();
  }, []);

  const getMonthsWithYears = (data) => {
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const months = [];
    const profit = [];
    const productcost = [];
    // //console.log(moment(data[1]._id).format('LL'), 'resultresult');
    let date = new Date();

    for (let i = 0; i < 10; i++) {
      // Format the string to include the month and year, then add to the beginning of the array
      const monthAndYear = `${
        monthNames[date.getMonth()]
      }-${date.getFullYear()}`;

      // const monthAndYear = moment().month()
      months.unshift(monthAndYear); // Unshift to add to the beginning of the array

      const val = data.find((ele) => {
        if (moment(ele._id).month() == date.getMonth()) {
          return ele;
        }
      });

      // //console.log(date.getMonth(), val, "sakthibala");

      if (val != undefined) {
        profit.unshift(val.totalProjit);
        productcost.unshift(val.totalProductCost);
      } else {
        profit.unshift(0);
        productcost.unshift(0);
      }

      date.setMonth(date.getMonth() - 1); // Move one month back
    }
    const graph = {
      monthsData: months,
      profits: profit,
      productcosts: productcost,
    };
    return graph;
  };

  const getDashboardDetails = async () => {
    const payload = {
      id: user.id,
      store: selectedValue._id || "all",
    };
    // console.log(payload, "payload");

    const { status, result } = await getdashBoardOrder(payload);

    // console.log(status, result, "status,result");
    if (status) {
      setTotalorder(result.TotalOrders);
      setreviewnewProfit(result.totalReviewNew);
      setNewProduct(result.newProduct);
      setDate(result.orderlist);
      setTopSell(result.topSelleing);
      setDailyProfit(result.dailyProfit);
      const months = await getMonthsWithYears(result.graphData);
      setStoreList(result.storelist);
      //console.log(months, "monthsmonths");

      setGraphvalue(months);
    }
  };

  useEffect(() => {
    getDashboardDetails();
  }, [selectedValue]);

  const CustomInput = ({ value, onClick }) => (
    <button className="custom_input" onClick={onClick}>
      <img src={calender} className="img-fluid" alt="logo" />
      <span className="date">{value || t("SELECT_DATE")}</span>
      {!drpdwn && <img src={down} className="img-fluid" alt="logo" />}
      {drpdwn && <img src={down} className="img-fluid uparw" alt="logo" />}
    </button>
  );

  const columns = [
    {
      name: t("PRODUCT_NAME"),
      width: "300px",

      selector: (row) => row.ProductName,
    },
    {
      name: t("ORDERID"),
      width: "140px",
      selector: (row) => numberWithCommas(row.orderID),
    },
    {
      name: t("STORE_NAME"),
      width: "150px",
      selector: (row) => row.StoreName,
    },
    {
      name: t("DATE_TIME"),
      width: "180px",
      selector: (row) => moment(row.createdAt).format("LLL"),
      // width: "180px"
    },
  ];

  const columns1 = [
    {
      name: t("PRODUCT_NAME"),
      width: "300px",
      selector: (row) => row.data?.[0]?.ProductName,
    },
    {
      name: t("ORDERID"),
      width: "140px",
      selector: (row) => numberWithCommas(row.data?.[0]?.orderID),
    },
    {
      name: t("STORE_NAME"),
      width: "150px",
      selector: (row) => row.data?.[0].StoreName,
    },
    {
      name: t("TOTAL_SALE"),
      selector: (row) => row.totalSell,
    },
  ];

  // const data = [
  //   {
  //     date: 'Feb 05, 2024 17:42 PM',
  //     product: 'PowerA Enhanced Wired A7',
  //     quantity: 10,
  //     amount: '$130.00',
  //   },
  //   {
  //     date: 'Feb 05, 2024 17:42 PM',
  //     product: 'Gopro Hero9 Action Camera',
  //     quantity: 10,
  //     amount: '$130.00',
  //   },
  //   {
  //     date: 'Feb 05, 2024 17:42 PM',
  //     product: 'Portable Bluetooth Speaker',
  //     quantity: 10,
  //     amount: '$130.00',
  //   },
  //   {
  //     date: 'Feb 05, 2024 17:42 PM',
  //     product: 'The Phantom Tollbooth',
  //     quantity: 10,
  //     amount: '$130.00',
  //   },
  //   {
  //     date: 'Feb 05, 2024 17:42 PM',
  //     product: 'Airdopes 381 Tws Earbuds',
  //     quantity: 10,
  //     amount: '$130.00',
  //   },
  // ];

  const CustomNoDataMessage = () => (
    <div className="text-center py-5 my-5">
      <p> {t("PRODUCT_NOT_FOUND")}</p>
    </div>
  );

  const handleSelect = (selectedItem) => {
    // //console.log(JSON.parse(selectedItem), "selectedItem");
    setSelectedValue(JSON.parse(selectedItem));
  };

  const getshopMaintenance = async () => {
    var { status, result } = await shopMaintenance();
    // console.log(status, result, "ffffff");
    if (status) {
      setmaintenance(result);
    }
  };

  const numberWithCommas = (number) => {
    return number?.toLocaleString();
  };

  return (
    <div className="page_wrapper">
      <ScrollToTopOnMount />

      <Sidebar navLink={"/dashboard"} />
      <div className="w-100">
        <Navbarinner />
        <div className="right_wrapper">
          <div className="dashboard">
            <div className="h_flx">
              <h3 className="h3tag mb-0">{t("DASHBOARD")}</h3>
              <div className="right">
                {/* <div className="input_box"> */}
                {/* <label className="sem sm_txt mb-3" >Category</label> */}
                <div className="drp_dwn drp_dwn_hgt mb-6 w-100 ">
                  <Dropdown onSelect={handleSelect}>
                    <Dropdown.Toggle className="d-flex justify-content-between">
                      {selectedValue != "all"
                        ? ` ${
                            selectedValue?.store_name.charAt(0).toUpperCase() +
                            selectedValue.store_name.slice(1)
                          }-${selectedValue?.default_storeid?.Store_name}`
                        : t("ALL")}
                    </Dropdown.Toggle>

                    <Dropdown.Menu className="w-100">
                      <Dropdown.Item eventKey={JSON.stringify("all")}>
                        {t("ALL")}
                      </Dropdown.Item>

                      {userstore.length > 0 &&
                        userstore.map((item) => (
                          <Dropdown.Item eventKey={JSON.stringify(item)}>
                            {item?.store_name?.charAt(0).toUpperCase() +
                              item?.store_name?.slice(1)}
                            -{item?.default_storeid?.Store_name}
                          </Dropdown.Item>
                        ))}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
                {/* </div> */}
                {/* <div className="custom_date" >
                  <DatePicker
                    selectsRange={true}
                    startDate={startDate}
                    endDate={endDate}
                    onChange={(update) => {
                      setDateRange(update);
                    }}
                    onCalendarOpen={() => {
                      setdrpdwn(true);
                    }}
                    onCalendarClose={() => {
                      setdrpdwn(false);
                    }}
                    customInput={<CustomInput />}
                  />
                </div> */}
                <div className="curr">{t("CURRENCY")}: USD</div>
              </div>
            </div>
            <div className="abv_box">
              <div
                className="box"
                data-aos="fade-up"
                data-aos-delay="600"
                data-aos-duration="1000"
              >
                <div>
                  <img src={revenue} style={{ width: "70px" }} alt="logo" />
                </div>
                <div>
                  <p>{t("DAILY_PROFIT")}</p>
                  <h5>
                    ${" "}
                    {dailyProfit?.length > 0
                      ? numberWithCommas(Number(dailyProfit?.[0]?.totalProjit))
                      : 0}
                  </h5>
                </div>
              </div>

              <div
                className="box"
                data-aos="fade-up"
                data-aos-delay="400"
                data-aos-duration="1000"
              >
                <div>
                  <img src={tot_order} style={{ width: "70px" }} alt="logo" />
                </div>
                <div>
                  <p>{t("TOTAL_ORDERS")}</p>
                  <h5>{numberWithCommas(totalOrder)}</h5>
                </div>
              </div>

              <div
                className="box"
                data-aos="fade-up"
                data-aos-delay="200"
                data-aos-duration="1000"
              >
                <div>
                  <img src={profit} style={{ width: "70px" }} alt="logo" />
                </div>
                <div>
                  <p>{t("TOTAL_PROFIT")}</p>
                  <h5>
                    ${" "}
                    {user.User_profit?.toFixed(2) > 0
                      ? numberWithCommas(user.User_profit)
                      : 0}
                  </h5>
                </div>
              </div>

              <div
                className="box"
                data-aos="fade-up"
                data-aos-delay="800"
                data-aos-duration="1000"
              >
                <div>
                  <img
                    src={new_products}
                    style={{ width: "70px" }}
                    alt="logo"
                  />
                </div>
                <div>
                  <p>{t("MY_PRODUCTS")}</p>
                  <h5>
                    {myproduct?.completed?.length
                      ? myproduct?.completed?.length
                      : 0}
                  </h5>
                </div>
              </div>
              <div
                className="box"
                data-aos="fade-up"
                data-aos-delay="1000"
                data-aos-duration="1000"
              >
                <div>
                  <img
                    src={new_products}
                    style={{ width: "70px" }}
                    alt="logo"
                  />
                </div>
                <div>
                  <p>{t("NEW_PRODUCTS")}</p>
                  <h5>{numberWithCommas(newProduct)}</h5>
                </div>
              </div>
            </div>
            <div className="chart_flx">
              <div
                className="box first flex-column align-items-stretch"
                data-aos="fade-up"
                data-aos-delay="200"
                data-aos-duration="1000"
              >
                <div className="chart_box">
                  <div className="d-flex align-items-center justify-content-between flex-wrap gap-2">
                    <h5>{t("SALES_OVERVIEW")}</h5>

                    <div className="chart-btn-group">
                      <button
                        className={`chart-btn mb-2 ${
                          type == "l10" ? "chart-btn-active" : ""
                        }`}
                        onClick={() => handlePeriodChange("l10")}
                      >
                        10 {t("DAYS")}
                      </button>
                      <button
                        className={`chart-btn mb-2 ${
                          type == "year" ? "chart-btn-active" : ""
                        }`}
                        onClick={() => handlePeriodChange("year")}
                      >
                        1 {t("WEEK")}
                      </button>
                      <button
                        className={`chart-btn mb-2 ${
                          type == "month" ? "chart-btn-active" : ""
                        }`}
                        onClick={() => handlePeriodChange("month")}
                      >
                        1 {t("MONTH")}
                      </button>

                      <button
                        className={`chart-btn mb-2 ${
                          type == "overall" ? "chart-btn-active" : ""
                        }`}
                        onClick={() => handlePeriodChange("overall")}
                      >
                        {t("OVERALL")}
                      </button>
                    </div>
                  </div>
                  <MyChart ref={chartRef} selecte={selectedValue} />
                </div>
              </div>
              <div className="w-100 d-flex flex-column gap-35">
                <div
                  className="box overview_box align-items-start"
                  data-aos="fade-up"
                  data-aos-delay="200"
                  data-aos-duration="1000"
                >
                  <div className="w-100">
                    <h5>{t("OVERVIEW")}</h5>
                    <ul>
                      <li>
                        <p className="mem">{t("TOTAL_PROFIT")}</p>
                        <span className="dark">
                          $
                          {reviewProfit?.length > 0
                            ? numberWithCommas(reviewProfit?.[0]?.totalProjit)
                            : 0}
                        </span>
                      </li>
                      <li>
                        <p className="mem">{t("TOTAL_SELL_COST")}</p>
                        <span className="dark">
                          $
                          {reviewProfit?.length > 0
                            ? numberWithCommas(
                                reviewProfit?.[0]?.totalSellorder
                              )
                            : 0}
                        </span>
                      </li>
                      {/*  <li>
                        <p className="mem">Total Buy Order Cost</p>
                        <span className="dark">
                          $
                          {reviewProfit?.length > 0
                            ? numberWithCommas(reviewProfit?.[0]?.totalproductcost)
                            : 0}
                        </span>
                      </li>*/}
                      {/* <li>
                      <p className="mem">Max Profit On Order</p>
                      <span className="dark">$0</span>
                    </li>
                    <li>
                      <p className="mem">Total Products Cost</p>
                      <span className="dark">$0</span>
                    </li> */}
                    </ul>
                  </div>
                </div>
                <div
                  className="box overview_box overview_box_chg align-items-start"
                  data-aos="fade-up"
                  data-aos-delay="400"
                  data-aos-duration="1000"
                >
                  <div className="w-100">
                    <h5>{t("MAINTENANCE_FEE")}</h5>
                    <ul>
                      <li className="gap-2">
                        <p className="mem text-nowrap">{t("SHOP")}</p>
                        <div className="w-100 d-flex gap-1 align-items-center">
                          <div class="progress  w-100">
                            <div
                              class="progress-bar text-end"
                              role="progressbar"
                              style={{ width: `${maintenance?.storeper}%` }}
                              aria-valuenow="25"
                              aria-valuemin="0"
                              aria-valuemax="100"
                            ></div>
                          </div>
                          <div className="small">${maintenance?.storeper}</div>
                        </div>
                      </li>
                      <li className="gap-2 align-items-start">
                        <p className="mem text-nowrap">{t("PACKAGE")}</p>
                        <div className="w-100 d-flex gap-1 align-items-center">
                          <div class="progress  w-100">
                            <div
                              class="progress-bar text-end"
                              role="progressbar"
                              style={{ width: `${maintenance?.packper}%` }}
                              aria-valuenow="15"
                              aria-valuemin="0"
                              aria-valuemax="100"
                            ></div>
                          </div>
                          <div className="small">
                            ${numberWithCommas(maintenance?.packper)}
                          </div>
                        </div>
                      </li>
                      <li className="gap-2 align-items-start">
                        <p className="mem text-nowrap">{t("ADVERTISEMENT")} </p>
                        <div className="w-100 d-flex gap-1 align-items-center">
                          <div class="progress  w-100">
                            <div
                              class="progress-bar text-end"
                              role="progressbar"
                              style={{ width: `${maintenance?.adspackper}%` }}
                              aria-valuenow="50"
                              aria-valuemin="0"
                              aria-valuemax="100"
                            ></div>
                          </div>
                          <div className="small">
                            ${numberWithCommas(maintenance?.adspackper)}
                          </div>
                        </div>
                      </li>
                      <li className="gap-2 align-items-start">
                        <p className="mem text-nowrap">{t("SHIPPING")} </p>
                        <div className="w-100 d-flex gap-1 align-items-center">
                          <div class="progress  w-100">
                            <div
                              class="progress-bar text-end"
                              role="progressbar"
                              style={{ width: `${maintenance?.shippackper}%` }}
                              aria-valuenow="50"
                              aria-valuemin="0"
                              aria-valuemax="100"
                            ></div>
                          </div>
                          <div className="small">
                            ${numberWithCommas(maintenance?.shippackper)}
                          </div>
                        </div>
                      </li>
                      <li className="gap-2 align-items-start">
                        <p className="mem text-nowrap">{t("STARTUP")} </p>
                        <div className="w-100 d-flex gap-1 align-items-center">
                          <div class="progress  w-100">
                            <div
                              class="progress-bar text-end"
                              role="progressbar"
                              style={{
                                width: `${maintenance?.startuppackper}%`,
                              }}
                              aria-valuenow="50"
                              aria-valuemin="0"
                              aria-valuemax="100"
                            ></div>
                          </div>
                          <div className="small">
                            ${numberWithCommas(maintenance?.startuppackper)}
                          </div>
                        </div>
                      </li>
                      <hr></hr>
                      <li>
                        <p className="mem">{t("TOTAL_FEE")}</p>
                        <span className="dark">
                          $
                          {numberWithCommas(
                            maintenance?.storeper +
                              maintenance?.packper +
                              maintenance?.adspackper +
                              maintenance?.shippackper +
                              maintenance?.startuppackper
                          )}
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="chart_flx"
              data-aos="fade-up"
              data-aos-delay="200"
              data-aos-duration="1000"
            >
              <div className="d-flex flex-column text-center align-items-start justify-content-center w-100 flex-wrap gap-2">
                <div className="box first flex-column align-items-stretch">
                  <div className="chart_box">
                    <h5 className="text-center mb-3">{t("WORLD_OVERVIEW")}</h5>
                    <div className="chart_bg mx-auto">
                      <MapChart selectedValue={selectedValue} />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="dash_blw_flx gap-xl-3 gap-xxl-5">
              <div
                className="box flex-column align-items-stretch overflow-x-auto"
                data-aos="fade-up"
                data-aos-delay="200"
                data-aos-duration="1000"
              >
                <div>
                  <div className="h_flx w-100">
                    <h5>{t("ORDER_LIST")}</h5>
                    <div className="right">
                      <Link to="/orders" className="atag">
                        {t("VIEW_ORDERS")}
                      </Link>
                    </div>
                  </div>
                  <div className="custom_table">
                    <DataTable
                      columns={columns}
                      data={data}
                      noDataComponent={<CustomNoDataMessage />}
                      responsive
                    />
                  </div>
                </div>
              </div>
              <div
                className="box flex-column align-items-stretch overflow-x-auto mt-3 mt-xl-0"
                data-aos="fade-up"
                data-aos-delay="400"
                data-aos-duration="1000"
              >
                <div>
                  <div className="h_flx w-100">
                    <h5>{t("TOP_SELLING")}</h5>
                  </div>

                  <div className="text-center ">
                    {topSell.length > 0 ? (
                      <DataTable
                        columns={columns1}
                        data={topSell}
                        noDataComponent={<CustomNoDataMessage />}
                        responsive
                      />
                    ) : (
                      <>
                        <img src={basket} className="img-fluid" alt="logo" />
                        <p className="mb-0"> {t("PRODUCT_NOT_FOUND")}</p>
                        <button
                          className="add_prd mx-auto my-0"
                          onClick={() => history("/marketplace")}
                        >
                          {" "}
                          + {t("ADD_PRODUCTS")}
                        </button>
                      </>
                    )}

                    {/* <div className="custom_table" > */}

                    {/* </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
