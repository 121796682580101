import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { QRCodeSVG } from "qrcode.react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import copy_icon from "../../assets/images/copy_icon.svg";
import ScrollToTopOnMount from "../ScrollToTopOnMount";
import Sidebar from "../sidebar.js";
import Navbarinner from "../Navbarinner.js";

// import lib
import { toastAlert, textCopy } from "../../utils/toast";
import isEmpty from "../../lib/isEmpty";
import { toFixedDown } from "../../lib/roundOf";

//import api
import { DepositRequest } from "../../action/walletAction";

//import validation
import { DepositValidation } from "./validation";

const initialFormValue = {
  amount: 0,
  image: "",
  memoAddress: "",
};

let imageType = ["jpg", "JPG", "jpeg", "JPEG", "png", "PNG", "pdf", "PDF"];

const Deposit = (props) => {
  //props
  const { coin } = props;

  // redux-state
  const walletData = useSelector((state) => state.wallet);
  const currency = useSelector((state) => state.currency);
  const siteDoc = useSelector((state) => state.siteSetting);

  const history = useNavigate();
  const { t, i18n } = useTranslation();

  //state
  const [formValue, setFormValue] = useState(initialFormValue);
  const { amount, image, memoAddress } = formValue;
  const [selectAsset, setSelectAsset] = useState({});
  const [errors, setErrors] = useState({});
  const [loader, setLoader] = useState(false);

  //function
  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    if (name == "amount") {
      if (/[^0-9.]/.test(value)) return;
    }
    let formData = { ...formValue, ...{ [name]: toFixedDown(value, 8) } };
    setFormValue(formData);
    if (!isEmpty(errors)) {
      setErrors({});
    }
    if (value) {
      setLoader(false);
    }
  };

  const handleAddrss = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    let formData = { ...formValue, ...{ [name]: value } };
    setFormValue(formData);
    if (!isEmpty(errors)) {
      setErrors({});
    }
    if (value) {
      setLoader(false);
    }
  };

  const handleFile = async (e) => {
    const { name, files } = e.target;
    let formData = { ...formValue, ...{ [name]: files[0] } };
    setFormValue(formData);
    if (!imageType.includes(e.target.files[0].type.split("/")[1])) {
      return toastAlert("error", t("INVALID_IMAGE"), "deposit");
    }
    if (!isEmpty(errors)) {
      setErrors({});
    }
    if (files) {
      setLoader(false);
    }
  };

  const handleAsset = () => {
    let tempArr = [...walletData];
    currency?.length > 0 &&
      currency.map((item, index) => {
        let pairIndex =
          tempArr &&
          tempArr.findIndex((el) => {
            return el._id == item._id;
          });
        if (pairIndex >= 0 && !isEmpty(pairIndex)) {
          tempArr[pairIndex] = {
            ...tempArr[pairIndex],
            ...{
              image: item.image,
              type: item.type,
              bankDetails: item.bankDetails,
              minDeposit: item.minimumDeposit,
              adminAddress: item.adminAddress,
              status: item.status,
            },
          };
        }
      });
    let checkIndex =
      tempArr &&
      tempArr.findIndex((el) => {
        return el._id == coin;
      });
    if (checkIndex >= 0) {
      setSelectAsset(tempArr[checkIndex]);
    } else {
      let newArr = tempArr.filter((item) => {
        return item.status == "active";
      });
      setSelectAsset(newArr[0]);
    }
  };

  const handleSubmit = async () => {
    try {
      if (selectAsset?.type != "fiat") {
        if (isEmpty(selectAsset?.adminAddress || selectAsset.address)) {
          toastAlert("error", t("MAINTENANCE_ERROR"), "DepositRequest");
          return;
        }
      } else {
        if (isEmpty(selectAsset?.bankDetails)) {
          toastAlert("error", t("MAINTENANCE_ERROR"), "DepositRequest");
          return;
        }
      }

      let reqData = {
        userAssetId: coin,
        minimumDeposit: selectAsset?.minDeposit,
        amount,
        image,
      };
      let validationError = DepositValidation(reqData, t);
      if (!isEmpty(validationError)) {
        setErrors(validationError);
        setLoader(false);
        return;
      }

      const formData = new FormData();
      formData.append("userAssetId", coin);
      formData.append("amount", amount);
      formData.append("type", selectAsset?.type);
      formData.append("image", image);
      formData.append("memoAddress", memoAddress);
      setLoader(true);
      const { status, loading, message, errors, il8 } = await DepositRequest(
        formData
      );
      if (status === "success") {
        setLoader(false);
        toastAlert("success", t(message, il8), "DepositRequest");
        setErrors({});
        setFormValue(initialFormValue);
        history("/wallet");
      } else {
        setLoader(false);
        if (errors) {
          let _error = Object.values(errors)[0];
          setErrors(errors);
          toastAlert(
            "error",
            _error ? t(_error) : "Something went wrong",
            "DepositRequest"
          );
          return;
        } else {
          return toastAlert("error", t(message, il8), "DepositRequest");
        }
      }
    } catch (err) {
      setLoader(false);
      console.log(err, "error");
    }
  };

  useEffect(() => {
    if (!isEmpty(walletData) && !isEmpty(currency)) handleAsset();
  }, [walletData, currency]);

  console.log("selectAsset", selectAsset);

  return (
    <div className="dash_wrapper">
      {/* <ScrollToTopOnMount /> */}
      {/* <Sidebar /> */}
      <div className="w-100">
        {/* <Navbarinner /> */}
        <div className="right_wrapper page_margin_top">
          <div className="dash_box asset_dashbox_flex asset_dashbox_flex_line">
            <div className="row">
              <div className="col-lg-6">
                <div className="asset_dashbox_flex_left">
                  <h3 className="asset_deposit_innertitle">
                    {t("DEPOSIT_TERMS")}
                  </h3>
                  <div className="">
                    <div
                      role="alert"
                      className="primary_warning_alert alert alert-secondary"
                    >
                      <div className="deposite_flx">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="26"
                          height="27"
                          viewBox="0 0 26 27"
                          fill="none"
                        >
                          <path
                            d="M13 26.7017C5.81491 26.7017 0 20.879 0 13.6829C0 6.48749 5.81425 0.664062 13 0.664062C20.1851 0.664062 26 6.48673 26 13.6829C26 20.8783 20.1857 26.7017 13 26.7017ZM14.335 8.51497C14.335 8.10365 13.7361 7.69244 13.0003 7.69244C12.2302 7.69244 11.6827 8.10365 11.6827 8.51497V15.0441C11.6827 15.5239 12.2303 15.8495 13.0003 15.8495C13.7361 15.8495 14.335 15.5239 14.335 15.0441V8.51497ZM13.0003 17.426C12.2131 17.426 11.5972 18.0086 11.5972 18.6599C11.5972 19.3111 12.2132 19.8766 13.0003 19.8766C13.7704 19.8766 14.3864 19.3111 14.3864 18.6599C14.3864 18.0086 13.7703 17.426 13.0003 17.426Z"
                            fill="#e39a65"
                          />
                        </svg>
                        <h3 className="mb-0">
                          {t("MINIMUM_DEPOSIT_AMOUNT")}{" "}
                          {selectAsset?.minDeposit} {selectAsset?.coin}
                        </h3>
                      </div>
                      <div className="alertCard">
                        <p className="mt-3 mb-0">{t("DEPOSIT_DESCRIPTION")}</p>
                      </div>
                    </div>
                  </div>
                  <ul className="deposit_notes_list">
                    <li>
                      {t("DEPOSIT_NOTES1", {
                        CURRENCY_SYMBOL: selectAsset?.coin,
                      })}
                    </li>
                    <li>
                      {t("DEPOSIT_NOTES2", {
                        CURRENCY_SYMBOL: selectAsset?.coin,
                      })}
                    </li>
                    <li>{t("DEPOSIT_NOTES3")}</li>
                  </ul>
                  {/* <div className="text-center mt-4 mb-4">
                    <a href={siteDoc?.externalLink} target="_blank" > <button type="button" className="w-100 primary_btn primary_btn_with_bg primary_btn_transform">
                      {t("DEPOSIT_CARD")}
                    </button>
                    </a>
                  </div> */}
                </div>
              </div>
              <div className="col-lg-6">
                <div className="asset_dashbox_flex_right ms-lg-3">
                  <h3 className="asset_deposit_innertitle">
                    {selectAsset?.coin} {t("DEPOSIT")}
                  </h3>
                  <div className="text-center my-3">
                    {!isEmpty(
                      selectAsset?.adminAddress || selectAsset.address
                    ) && (
                      <QRCodeSVG
                        value={
                          !isEmpty(selectAsset.address)
                            ? selectAsset.address
                            : selectAsset.adminAddress
                        }
                      />
                    )}
                  </div>
                  <form className="assets_form">
                    {selectAsset?.type == "crypto" && (
                      <div className="mb-3">
                        <label for="depositaddress" className="form-label">
                          {t("DEPOSIT_ADDRESS")}
                        </label>
                        <div className="asset_deposit_grp">
                          <input
                            type="text"
                            className="form-control primary_asset_inp"
                            id="depositaddress"
                            value={
                              !isEmpty(selectAsset.address)
                                ? selectAsset.address
                                : selectAsset.adminAddress
                            }
                            disabled
                          />
                          <img
                            src={copy_icon}
                            alt="Copy"
                            className="asset_copy_icon"
                            onClick={() =>
                              textCopy(
                                !isEmpty(selectAsset.address)
                                  ? selectAsset.address
                                  : selectAsset.adminAddress,
                                t
                              )
                            }
                          />
                        </div>
                      </div>
                    )}
                    {selectAsset?.coin === "XRP" && (
                      <div className="mb-3">
                        <label for="email" className="form-label">
                          {t("MEMO_ADDRESS")}
                        </label>
                        <input
                          type="text"
                          name="memoAddress"
                          value={memoAddress}
                          onChange={handleAddrss}
                          className="form-control primary_asset_inp"
                        />
                        {/* <span className='text-danger'>{errors?.memoAddress}</span> */}
                      </div>
                    )}

                    {selectAsset.type == "fiat" && (
                      <>
                        <label for="depositaddress" className="form-label">
                          {t("BANK_DETAILS")}
                        </label>
                        <div className="mb-3">
                          <label for="depositaddress" className="form-label">
                            {t("ACCOUNTNO")}
                          </label>
                          <div className="asset_deposit_grp">
                            <input
                              type="text"
                              className="form-control primary_asset_inp"
                              id="depositaddress"
                              value={
                                !isEmpty(selectAsset.bankDetails?.accountNo)
                                  ? selectAsset.bankDetails?.accountNo
                                  : ""
                              }
                              disabled
                            />
                            <img
                              src={copy_icon}
                              alt="Copy"
                              className="asset_copy_icon"
                              onClick={() =>
                                textCopy(
                                  !isEmpty(selectAsset.bankDetails?.accountNo)
                                    ? selectAsset.bankDetails?.accountNo
                                    : "",
                                  t
                                )
                              }
                            />
                          </div>
                        </div>

                        <div className="mb-3">
                          <label for="depositaddress" className="form-label">
                            {t("BANK_NAME")}
                          </label>
                          <div className="asset_deposit_grp">
                            <input
                              type="text"
                              className="form-control primary_asset_inp"
                              id="depositaddress"
                              value={
                                !isEmpty(selectAsset.bankDetails?.bankName)
                                  ? selectAsset.bankDetails?.bankName
                                  : ""
                              }
                              disabled
                            />
                            <img
                              src={copy_icon}
                              alt="Copy"
                              className="asset_copy_icon"
                              onClick={() =>
                                textCopy(
                                  !isEmpty(selectAsset.bankDetails?.bankName)
                                    ? selectAsset.bankDetails?.bankName
                                    : "",
                                  t
                                )
                              }
                            />
                          </div>
                        </div>

                        <div className="mb-3">
                          <label for="depositaddress" className="form-label">
                            {t("BANK_CODE")}
                          </label>
                          <div className="asset_deposit_grp">
                            <input
                              type="text"
                              className="form-control primary_asset_inp"
                              id="depositaddress"
                              value={
                                !isEmpty(selectAsset.bankDetails?.bankcode)
                                  ? selectAsset.bankDetails?.bankcode
                                  : ""
                              }
                              disabled
                            />
                            <img
                              src={copy_icon}
                              alt="Copy"
                              className="asset_copy_icon"
                              onClick={() =>
                                textCopy(
                                  !isEmpty(selectAsset.bankDetails?.bankcode)
                                    ? selectAsset.bankDetails?.bankcode
                                    : "",
                                  t
                                )
                              }
                            />
                          </div>
                        </div>

                        <div className="mb-3">
                          <label for="depositaddress" className="form-label">
                            {t("HOLDER_NAME")}
                          </label>
                          <div className="asset_deposit_grp">
                            <input
                              type="text"
                              className="form-control primary_asset_inp"
                              id="depositaddress"
                              value={
                                !isEmpty(selectAsset.bankDetails?.holderName)
                                  ? selectAsset.bankDetails?.holderName
                                  : ""
                              }
                              disabled
                            />
                            <img
                              src={copy_icon}
                              alt="Copy"
                              className="asset_copy_icon"
                              onClick={() =>
                                textCopy(
                                  !isEmpty(selectAsset.bankDetails?.holderName)
                                    ? selectAsset.bankDetails?.holderName
                                    : "",
                                  t
                                )
                              }
                            />
                          </div>
                        </div>
                        <div className="mb-3">
                          <label for="depositaddress" className="form-label">
                            {t("COUNTRY")}
                          </label>
                          <div className="asset_deposit_grp">
                            <input
                              type="text"
                              className="form-control primary_asset_inp"
                              id="depositaddress"
                              value={
                                !isEmpty(selectAsset.bankDetails?.country)
                                  ? selectAsset.bankDetails?.country
                                  : ""
                              }
                              disabled
                            />
                            <img
                              src={copy_icon}
                              alt="Copy"
                              className="asset_copy_icon"
                              onClick={() =>
                                textCopy(
                                  !isEmpty(selectAsset.bankDetails?.country)
                                    ? selectAsset.bankDetails?.country
                                    : "",
                                  t
                                )
                              }
                            />
                          </div>
                        </div>
                      </>
                    )}

                    <div className="mb-3"></div>
                    <div className="mb-3">
                      <label for="email" className="form-label">
                        {t("AMOUNT")}
                      </label>
                      <input
                        type="number"
                        name="amount"
                        value={amount}
                        onChange={handleChange}
                        className="form-control primary_asset_inp"
                      />
                      <span className="text-danger">{t(errors?.amount)}</span>
                    </div>
                    <div className="mb-3">
                      <div className="deposite_block">
                        <label for="withdrawamount" className="form-label">
                          {t("DOCUMENT_UPLOAD")}
                        </label>
                        <div className="custom_file_upload btn">
                          {t("UPLOAD")}
                          <input
                            type="file"
                            className="file_input"
                            name="image"
                            onChange={handleFile}
                          />
                        </div>
                      </div>
                      <div className="form-text mt-2">
                        {t("DEPOSIT_IMAGE_RESTRICTION")}
                      </div>
                      <span className="text-danger">{t(errors?.image)}</span>
                    </div>
                    {image?.name && (
                      <div className="uploaded_files_row">
                        <div>
                          <i className="bi bi-file-earmark-plus"></i>
                          <span>{image?.name}</span>
                        </div>
                        <div>
                          <i
                            className="bi bi-x-circle"
                            onClick={() =>
                              setFormValue({
                                ...formValue,
                                ...{ ["image"]: "" },
                              })
                            }
                          ></i>
                        </div>
                      </div>
                    )}
                    <div className=" mt-4 mb-4">
                      <button
                        type="button"
                        onClick={handleSubmit}
                        disabled={loader}
                        className="primary_btn primary_btn_with_bg primary_btn_transform"
                      >
                        {t("DEPOSIT")}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Deposit;
