import React, { useState, useEffect } from "react";
import { NavLink, Link } from "react-router-dom";
// import usr from "../assets/images/usr.svg";
import usr from "../assets/images/2-removebg-preview.png";

import notification from "../assets/images/notification.svg";
import logo from "../assets/images/logo.png";
import robo from "../assets/images/favicon.svg";

import colornotify from "../assets/images/msg1176172984-45845-removebg-preview.png";
import chopCart from "../assets/images/msg1176172984-45846-removebg-preview.png";

import balance from "../assets/images/Removal-828.png";
import draft from "../assets/images/cart.png";
import { useCookies } from "react-cookie";
import { useDispatch, useSelector } from "react-redux";
import DraftPop from "../components/draft/DraftPop";
import axios from "axios";
import { Image } from "react-bootstrap";

import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import config from "../config/index";
import {
  PaymentElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import CheckoutPage from "./CheckoutPage";
import {
  CreateInstant,
  getPlanData,
  getSiteSetting,
  updateNotifyCount,
  withDrawRequest,
} from "../action/user.action";
import { accountReset } from "../redux/features/user/userslice";
import { toastAlert } from "../utils/toast";
import io from "socket.io-client";
import { getNotify } from "../redux/features/notification/notificationSlice";
import ThemeSwitcher from "./ThemeSwitcher";
import { useTheme } from "./ThemeContext";
import { getLang, setLang } from "../lib/localStorage";
import { useTranslation } from "react-i18next";
import { isEmpty } from "../helper/validation";

const socketInstance = io(config.API_URL);

export default function Navbarinner() {
  const dispatch = useDispatch();

  const [show2, setShow2] = useState(false);
  const [clientSecret, setClientSecret] = useState("");
  const [usdAmount, setUsdAmount] = useState(0);
  const [Validation, setValidation] = useState(null);
  const [withdraw, setWithdrawAMt] = useState(0);
  const [error, setError] = useState({});
  const [sitesetting, setSiteSettings] = useState({});

  const [cookies, setCookie, removeCookie] = useCookies(["name"]);
  const [PlanColor, setPlancolor] = useState("");
  const user = useSelector((state) => state.account);

  // console.log(user, 'user.id');
  const userdraft = useSelector((state) => state?.userdraft);
  const notify = useSelector((state) => state?.notify);
  const [isLoading, setIsLoading] = useState();
  const [notifycount, setnotifyCount] = useState(0);
  const [notification, setNotification] = useState([]);
  const { theme } = useTheme();

  // let error = {}

  const stripePromise = loadStripe(config.stripe);

  //socket

  useEffect(() => {
    // listen for events emitted by the server

    socketInstance.on("connect", () => {
      console.log("Connected to server");
    });

    socketInstance.emit("CREATEROOM", user.id);

    socketInstance.emit("message", "hii");
    socketInstance.on("notice", (data) => {
      // console.log(`Received message:`, data);
      setnotifyCount(data?.notifiyCount);
      setNotification(data?.NoticeData);
    });

    socketInstance.on("auth", (data) => {
      // console.log(data,'ddddddddddddddd');
      Removecookies();
    });

    return () => {
      if (socketInstance) {
        socketInstance.disconnect();
      }
    };
  }, [notifycount]);

  const clearNotification = () => {
    setnotifyCount(null);
    setNotification("");
    setnotifyCount(0);
  };

  const Removecookies = () => {
    removeCookie("userToken");
    removeCookie("loggedin");
    dispatch(accountReset(null));
    window.location.href = "/login";
  };

  const fetchData = async () => {
    try {
      const { status, result } = await getSiteSetting();

      // console.log(result, 'SAKTHIBALA');
      if (status) {
        setSiteSettings(result);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchPlanData = async () => {
    try {
      const payload = {
        plan_id: user.plan_id,
      };
      const { status, result } = await getPlanData(payload);

      // console.log(result, 'SAKTHIBALA 11111');
      if (status) {
        setPlancolor(result?.color);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchData();
    fetchPlanData();
  }, [PlanColor]);

  // console.log(PlanColor, 'PlanColorPlanColorPlanColor');

  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  const createPayment = async () => {
    if (usdAmount == "") {
      setValidation(t("AMOUNT_REQUIRED"));
      return false;
    }
    if (usdAmount < Number(sitesetting.minimumDeposit)) {
      setValidation(t("AMOUNT_ERROR", { AMOUNT: sitesetting.minimumDeposit }));
      return false;
    }
    if (isNaN(usdAmount)) {
      setValidation(t("INVALID_AMOUNT"));
      return false;
    }

    const payload = {
      amount: usdAmount,
      id: user.id,
    };

    // console.log("Sending request with payload:", payload);

    try {
      setIsLoading(true);

      const response = await CreateInstant(payload);
      // console.log("Response received:", response);
      setClientSecret(response.result);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error("Error:", error);
    }
  };

  const appearance = {
    theme: "stripe",
  };
  const options = {
    clientSecret,
    appearance,
  };

  const withdrawValidation = async (val) => {
    // console.log(val, 'sakythi');

    const error = {};

    const numReg = /^[0-9]*$/;

    if (val == 0) {
      error.withDraw = t("WITHDRAW_AMOUNT_REQUIRED");
    } else if (val > Number(user.deposit_Balance)) {
      error.withDraw = t("BALANCE_TOO_LOW");
    } else if (numReg.test(val) == false) {
      error.withDraw = t("INVALID_WITHDRAW_AMOUNT");
    } else if (val < Number(sitesetting.minimumWithdraw)) {
      error.withDraw = t("AMOUNT_ERROR", {
        AMOUNT: sitesetting.minimumWithdraw,
      });
    }
    return error;
  };

  const WithDrawHandler = async () => {
    try {
      const Payload = {
        id: user.id,
        amount: withdraw,
      };

      // console.log(withdraw, 'sakthi');

      const err = await withdrawValidation(withdraw);

      if (Object.keys(err).length == 0) {
        setError({});
        setIsLoading(true);
        const { status, message } = await withDrawRequest(Payload);

        // console.log(status, message);
        if (status) {
          toastAlert("success", t(message));

          setTimeout(() => {
            window.location.reload();
          }, 2500);
        } else {
          toastAlert("error", t(message));
        }
      } else {
        setIsLoading(false);
        setError(err);
      }
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  const readNotification = async () => {
    try {
      const { status, result } = await updateNotifyCount();

      // console.log(status, result, 'Received message');

      // if (status) {
      // setnotifyCount(0)

      //  await dispatch(getNotify())
      // }
    } catch (error) {
      console.log(error);
    }
  };

  const numberWithCommas = (number) => {
    return number.toLocaleString();
  };

  const { t, i18n } = useTranslation();

  const langList = [
    {
      value: "en",
      image: require("../assets/images/EN.png"),
      label: "English",
    },
    { value: "ar", image: require("../assets/images/AR.png"), label: "Arabic" },
    { value: "fr", image: require("../assets/images/FR.png"), label: "French" },
    {
      value: "sp",
      image: require("../assets/images/SP.png"),
      label: "Spanish",
    },
  ];

  const [defaultLang, setDefaultLang] = useState({
    value: "en",
    image: require("../assets/images/EN.png"),
    label: "English",
  });

  const [language, setLanguage] = useState("en");

  const handleLanguage = (lang, option) => {
    setLang(lang);
    setLanguage(lang);
    setDefaultLang(option);
    i18n.changeLanguage(lang);
  };

  useEffect(() => {
    let langCode = getLang();

    if (isEmpty(langCode)) {
      setLang(language);
      i18n.changeLanguage(language);
    } else {
      setLang(langCode);
      i18n.changeLanguage(langCode);
      const selectedLang = langList.find((item) => item.value === langCode);
      if (selectedLang) setDefaultLang(selectedLang);
      setLanguage(langCode);
    }
  }, [language]);

  const [totalUSD, setTotalUSD] = useState(0);

  const currency = useSelector((state) => state.currency);
  const walletData = useSelector((state) => state.wallet);
  const priceConversion = useSelector((state) => state.priceconversion);
  const defaultcurrency = "USD";

  const handleAsset = () => {
    try {
      let totalAmount = 0;
      let tempArr = [...walletData];
      currency?.length >= 0 &&
        currency.map((item, index) => {
          let PriceCnv = priceConversion.find(
            (el) =>
              el.baseSymbol == item.currencySymbol && el.convertSymbol == "USD"
          );
          let pairIndex =
            tempArr &&
            tempArr.findIndex((el) => {
              return el._id == item._id;
            });
          if (pairIndex >= 0 && !isEmpty(pairIndex)) {
            let Balance = tempArr[pairIndex].balance;
            tempArr[pairIndex] = {
              ...tempArr[pairIndex],
              ...{
                USDValue: !isEmpty(PriceCnv?.convertPrice)
                  ? parseFloat(Balance * PriceCnv.convertPrice)
                  : Balance,
              },
            };
            totalAmount += tempArr[pairIndex].USDValue;
          }
        });

      if (defaultcurrency == "USD") {
        setTotalUSD(totalAmount);
      }
    } catch (err) {
      console.log("err:------ ", err);
    }
  };

  useEffect(() => {
    if (
      !isEmpty(walletData) &&
      !isEmpty(currency) &&
      !isEmpty(priceConversion)
    ) {
      handleAsset();
    }
  }, [walletData, currency, priceConversion, defaultcurrency]);

  // console.log(sitesetting, '-sitesettingsitesetting')

  return (
    <>
      <nav className="navbar nav_inner  navbar-expand-xl">
        <Link className="navbar-brand d-xl-none d-block" to="/">
          <img
            src={robo}
            className="img-fluid user_drp pe-lg-4 pe-2 d-xl-none  "
            alt="logo"
          />
        </Link>
        <div className="d-flex justify-content-end gap-2 w-100">
          {/* <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasNavbar"
            aria-controls="offcanvasNavbar"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button> */}
          <div className="notification dropdown user_drp pe-lg-4 pe-2 d-xl-none mt-1">
            <div className="icon mt-2">
              <i
                class="fa badge1"
                // style={{ color: "black", fontSize: "18px", cursor: "pointer" }}
                value={userdraft.length}
                onClick={handleShow2}
              ></i>

              <img
                src={chopCart}
                alt="badge icon"
                className="badge1"
                style={{ color: "black", cursor: "pointer", width: "35px" }}
                value={userdraft.length}
                onClick={handleShow2}
              />
            </div>
          </div>
          <div className="notification dropdown user_drp pe-lg-4 pe-2 d-xl-none mt-1">
            <div
              className="dropdown-toggle mt-2"
              href="#"
              role="button"
              id="dropdownMenuLink1"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <div className="icon">
                <i
                  class="fa badge1"
                  style={{
                    color: "black",
                    fontSize: "18px",
                    cursor: "pointer",
                  }}
                  value={notifycount == 0 ? notify.length : notifycount}
                  onClick={readNotification}
                >
                  {/* &#xf0f3; */}
                </i>

                <img
                  src={colornotify}
                  alt="badge icon"
                  className="badge1"
                  style={{ color: "black", cursor: "pointer", width: "30px" }}
                  value={notifycount == 0 ? notify.length : notifycount}
                  onClick={readNotification}
                />

                {/* <img src={notification} class="img-fluid" alt="img" /> */}
              </div>
              <ul
                className="dropdown-menu walletdrop"
                aria-labelledby="dropdownMenuLink1"
              >
                <li>
                  <a className="dropdown-item hd" href="#">
                    {t("NOTIFICATION")}
                  </a>
                </li>
                {notifycount == 0
                  ? notify.length > 0 &&
                    notify?.map((item, index) => (
                      <li>
                        <a className="dropdown-item" href="#">
                          {item?.description}
                        </a>
                      </li>
                    ))
                  : notification.length > 0 &&
                    notification?.map((item, index) => (
                      <li>
                        <a className="dropdown-item" href="#">
                          {item?.description}
                        </a>
                      </li>
                    ))}
                <li>
                  <a
                    className="dropdown-item clear"
                    href="#"
                    onClick={clearNotification}
                  >
                    {t("CLEARALL")}
                  </a>
                </li>
              </ul>
            </div>
          </div>

          <div className="dropdown user_drp pe-lg-4 pe-2 d-xl-none mt-1">
            <a
              className="dropdown-toggle"
              href="#"
              role="button"
              id="dropdownMenuLink"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <img
                src={usr}
                style={{
                  width: "44px",
                }}
                class="img-fluid"
                alt="img"
              />
              {/* <span>Steve Smith</span> */}
            </a>
            <ul
              className="dropdown-menu walletdrop "
              aria-labelledby="dropdownMenuLink"
            >
              {/* <li>
                <Link to={"/kyc"} className="dropdown-item">
                  Bank Details
                </Link>
              </li> */}
              <li>
                <Link to={"/change-password"} className="dropdown-item">
                  {t("CHANGE_PASSWORD")}
                </Link>
              </li>

              <li>
                <button className="dropdown-item" onClick={Removecookies}>
                  {t("LOGOUT")}
                </button>
              </li>
            </ul>
          </div>
          <div
            className="offcanvas offcanvas-end"
            tabIndex={-1}
            id="offcanvasNavbar"
            aria-labelledby="offcanvasNavbarLabel"
          >
            <div className="offcanvas-header">
              <h5 className="offcanvas-title" id="offcanvasNavbarLabel"></h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
              ></button>
            </div>
            <div className="offcanvas-body">
              <div className="ms-auto navbar_inner_right">
                <div data-bs-dismiss="offcanvas" aria-label="Close">
                  <div className={`box`}>
                    {user.plan_type == "none" ? (
                      <a
                        href="/Packagesolution"
                        style={{ textDecoration: "none" }}
                        className="primary_btn market_btn"
                      >
                        {t("SUBSCRIPTION_PACKAGE")}
                      </a>
                    ) : (
                      <a
                        href="/Packagesolution"
                        style={{ textDecoration: "none" }}
                        className={`primary_btn market_btn abv_box ${PlanColor} `}
                      >
                        <span>
                          {" "}
                          {user.plan_type} {t("PACKAGE")}
                        </span>{" "}
                      </a>
                    )}
                  </div>
                  {/* PlanColor */}
                </div>

                {/* {sitesetting?.DepositAccess && (
                  <div data-bs-dismiss="offcanvas" aria-label="Close">
                    <button
                      className="primary_btn deposit"
                      data-bs-toggle="modal"
                      data-bs-target="#deposit_modal"
                    >
                      Deposit
                    </button>
                  </div>
                )} */}

                {/* {user.kycstatus == "approved" ? (
                  <>
                    {user.deposit_Balance == 0 ? (
                      <>
                        <button
                          className="primary_btn deposit"
                          style={{ opacity: "50%" }}
                          disabled
                        >
                          Withdraw
                        </button>
                      </>
                    ) : user.widthDrawStatus == "Withdraw" ? (
                      <button
                        className="primary_btn deposit"
                        data-bs-toggle="modal"
                        data-bs-target="#WithDraw_modal"
                      >
                        {user.widthDrawStatus}
                      </button>
                    ) : user.widthDrawStatus == "Pending" ? (
                      <button className="primary_btn deposit">
                        {user.widthDrawStatus}...
                      </button>
                    ) : (
                      ""
                    )}
                  </>
                ) : (
                  <>
                    <button
                      className="primary_btn deposit"
                      style={{ opacity: "50%" }}
                      disabled
                    >
                      Withdraw
                    </button>
                  </>
                )} */}

                <div className="balance">
                  <img src={balance} style={{ width: "45px" }} alt="img" />
                  <span>
                    {t("BALANCE")}: $ {numberWithCommas(totalUSD) || 0}
                  </span>
                </div>

                <span style={{ fontWeight: "bold" }}>
                  {t("WELCOME")}{" "}
                  {user?.userFullName?.charAt(0).toUpperCase() +
                    user?.userFullName?.slice(1)}
                </span>

                <div className="dropdown notification">
                  {/* <a
                    className="dropdown-toggle"
                    href="#"
                    role="button"
                    id="dropdownMenuLink1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  > */}

                  <div className="icon">
                    <i
                      class="fa badge1"
                      // style={{ color: "black", fontSize: "18px", cursor: "pointer" }}
                      value={userdraft.length}
                      onClick={handleShow2}
                    ></i>

                    <img
                      src={chopCart}
                      alt="badge icon"
                      className="badge1"
                      style={{
                        color: "black",
                        cursor: "pointer",
                        width: "32px",
                      }}
                      value={userdraft.length}
                      onClick={handleShow2}
                    />
                  </div>
                  {/* </a> */}
                  {/* <ul
                    className="dropdown-menu walletdrop "
                    aria-labelledby="dropdownMenuLink1"
                  >
                    <li>
                      <a className="dropdown-item" href="#">
                        Lorem ipsum dolor sit ame
                      </a>
                    </li>
                  </ul> */}
                </div>

                <div className="dropdown notification">
                  <a
                    className="dropdown-toggle"
                    href="#"
                    role="button"
                    id="dropdownMenuLink1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <div className="icon">
                      <i
                        class="fa badge1"
                        style={{
                          color: "black",
                          fontSize: "18px",
                          cursor: "pointer",
                        }}
                        value={notifycount == 0 ? notify.length : notifycount}
                        onClick={readNotification}
                      >
                        {/* &#xf0f3; */}
                      </i>

                      <img
                        src={colornotify}
                        alt="badge icon"
                        className="badge1"
                        style={{
                          color: "black",
                          cursor: "pointer",
                          width: "35px",
                        }}
                        value={notifycount == 0 ? notify.length : notifycount}
                        onClick={readNotification}
                      />

                      {/* <img src={notification} class="img-fluid" alt="img" /> */}
                    </div>
                  </a>
                  <ul
                    className="dropdown-menu walletdrop"
                    aria-labelledby="dropdownMenuLink1"
                  >
                    <li>
                      <a className="dropdown-item hd" href="#">
                        {t("NOTIFICATION")}
                      </a>
                    </li>
                    {notifycount == 0
                      ? notify.length > 0 &&
                        notify?.map((item, index) => (
                          <li>
                            <a className="dropdown-item" href="#">
                              {item?.description}
                            </a>
                          </li>
                        ))
                      : notification.length > 0 &&
                        notification?.map((item, index) => (
                          <li>
                            <a className="dropdown-item" href="#">
                              {item?.description}
                            </a>
                          </li>
                        ))}
                    <li>
                      <a
                        className="dropdown-item clear"
                        href="#"
                        onClick={clearNotification}
                      >
                        {t("CLEARALL")}{" "}
                      </a>
                    </li>
                  </ul>
                </div>

                <div className="dropdown user_drp">
                  <a
                    className="dropdown-toggle"
                    href="#"
                    role="button"
                    id="dropdownMenuLink"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <img
                      src={usr}
                      style={{
                        width: "53px",
                      }}
                      class="img-fluid"
                      alt="img"
                    />
                  </a>
                  <ul
                    className="dropdown-menu walletdrop "
                    aria-labelledby="dropdownMenuLink"
                  >
                    {/* <li>
                      <Link to={"/kyc"} className="dropdown-item">
                        Bank Details
                      </Link>
                    </li> */}
                    <li>
                      <Link to={"/change-password"} className="dropdown-item">
                        {t("CHANGE_PASSWORD")}
                      </Link>
                    </li>

                    <li>
                      <button className="dropdown-item" onClick={Removecookies}>
                        {t("LOGOUT")}
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
      <DraftPop show2={show2} handleClose2={handleClose2} />

      {/* Connect Wallet Modal */}
      <div
        className="modal fade primary_modal"
        id="deposit_modal"
        tabIndex={-1}
        aria-labelledby="connect_walletModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="connect_walletModalLabel">
                {t("DEPOSIT")} USD
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              {clientSecret ? (
                <>
                  <Elements options={options} stripe={stripePromise}>
                    <CheckoutPage />
                  </Elements>
                </>
              ) : (
                <div>
                  <div className="m-2 mb-3">
                    <div class="input_box mb-4">
                      <label class="mb-3">Amount</label>
                      <div class="input-group">
                        <span class="input-group-text pe-1">
                          <img
                            src={balance}
                            style={{ width: "45px" }}
                            alt="img"
                          />
                        </span>
                        <input
                          type="text"
                          class="form-control py-3"
                          name="name"
                          placeholder={t("DEPOSIT_PLACEHOLDER")}
                          onChange={(e) => setUsdAmount(e.target.value)}
                        />
                      </div>
                      {Validation ? (
                        <span style={{ color: "red" }}>{Validation}</span>
                      ) : (
                        ""
                      )}
                    </div>
                    <button
                      class="primary_btn mb-4 w-100 rounded-2"
                      onClick={createPayment}
                      disabled={isLoading}
                    >
                      <span id="button-text">
                        {isLoading ? (
                          <div className="spinner" id="spinner"></div>
                        ) : (
                          t("PROCEED_PAY")
                        )}
                      </span>
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* widthdraw model */}

      {/* Connect Wallet Modal */}
      <div
        className="modal fade primary_modal"
        id="WithDraw_modal"
        tabIndex={-1}
        aria-labelledby="connect_walletModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="connect_walletModalLabel">
                {t("WITHDRAW")} USD
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div>
                <div className="m-2 mb-3">
                  <div className="mb-3">
                    <span>
                      {t("BALANCE")} {t("AMOUNT")}: ${" "}
                      {(user?.deposit_Balance || 0).toFixed(2) || 0}
                    </span>
                  </div>
                  <div class="input_box mb-4">
                    <label class="mb-3">Withdraw Amount :</label>
                    <div class="input-group">
                      <span class="input-group-text pe-1">
                        <img
                          src={balance}
                          style={{ width: "45px" }}
                          alt="img"
                        />
                      </span>
                      <input
                        type="text"
                        class="form-control py-3"
                        name="name"
                        placeholder={t("WITHDRAW_PLACEHOLDER")}
                        onChange={(e) => setWithdrawAMt(e.target.value)}
                      />
                    </div>
                    {error && error.withDraw ? (
                      <span style={{ color: "red" }}>{error.withDraw}</span>
                    ) : (
                      ""
                    )}
                  </div>
                  <button
                    class="primary_btn mb-4 w-100 rounded-2"
                    onClick={WithDrawHandler}
                    disabled={isLoading}
                  >
                    {isLoading ? (
                      <div className="spinner" id="spinner"></div>
                    ) : (
                      t("PROCEED_WITHDRAW")
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Change Language Modal */}
      <div
        class="modal fade custom_modal"
        id="exampleModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered modal-sm">
          <div class="modal-content">
            <div class="modal-header">
              <h1
                class="modal-title fs-5"
                style={{ color: "black" }}
                id="exampleModalLabel"
              >
                {t("CHANGE_LANG")}
              </h1>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <ul className="flag_list">
                {langList.map((option, i) => (
                  <li
                    key={i}
                    onClick={() => handleLanguage(option.value, option)}
                    data-bs-dismiss="modal"
                    className={language === option.value ? "active" : ""}
                  >
                    <span>{option?.label}</span>
                    <Image src={option.image} width={24} height={24} />
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
