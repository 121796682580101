import React, { useEffect, useState, useRef, useMemo } from "react";
import DataTable from "react-data-table-component";
import { Scrollbars } from "react-custom-scrollbars-2";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

// img
import csv from "../assets/images/csv.svg";
import xlsx from "../assets/images/xlsx.svg";
import pdf from "../assets/images/pdf.svg";

import calender from "../assets/images/calender.svg";
import search_icon from "../assets/images/search_icon.svg";
import down from "../assets/images/down.svg";
import sort_arw from "../assets/images/sort_arw.svg";
import plus1 from "../assets/images/plus1.svg";

import Sidebar from "../components/sidebar.js";
import MyChart from "../components/linechart.js";
import MyLineChart from "../components/linechart.js";
import Navbarinner from "../components/Navbarinner.js";
import { Link } from "react-router-dom";
import { getOrderlist } from "../action/user.action";
import moment from "moment";

import { downloadCSV, downloadXls, downloadpdf } from "../helper/downloadFun";
import { Dropdown } from "react-bootstrap";
import ScrollToTopOnMount from "../components/ScrollToTopOnMount";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

export default function Orders() {
  const userstore = useSelector((state) => state.userstore);

  const { t } = useTranslation();

  // console.log(userstore, 'userstoreuserstore');

  const User = useSelector((state) => state?.account);

  const [dateRange, setDateRange] = useState([null, null]);

  const [startDate, endDate] = dateRange;

  const [drpdwn, setdrpdwn] = useState();
  const [record, setRecord] = useState({
    data: [],
    count: 0,
  });

  const [filter, setFilter] = useState({
    limit: 10,
    page: 1,
    search: "",
    startdate: "",
    enddate: "",
  });
  const [selectedValue, setSelectedValue] = useState("all");
  const [storeList, setStoreList] = useState([]);

  // console.log(dateRange,"dateRange");

  const getOrder = async (reqdata) => {
    try {
      reqdata.store = selectedValue?._id || "all";

      // console.log(reqdata, "reqdata");
      var { status, result, totalrecords, storelist } = await getOrderlist(
        reqdata
      );
      // console.log(result, 'resultresult');
      if (status) {
        setRecord({
          data: result,
          count: totalrecords,
        });
        setStoreList(storelist);
      }
    } catch (err) {}
  };

  const CustomInput = ({ value, onClick }) => (
    <button className="custom_input bg-transparent" onClick={onClick}>
      <img src={calender} className="img-fluid" alt="logo" />
      <span className="date">{value || t("SELECT_DATE")}</span>
      {!drpdwn && <img src={down} className="img-fluid" alt="logo" />}
      {drpdwn && <img src={down} className="img-fluid uparw" alt="logo" />}
    </button>
  );

  const columns = [
    {
      name: t("DATE_TIME"),
      selector: (row) => moment(row.createdAt).format("lll"),
      sortable: true,
      width: "160px",
    },
    {
      name: t("ORDERID"),
      selector: (row) => row.orderID,
      sortable: true,
      width: "110px",
      cell: (row) => <div className="reg">{row.orderID}</div>,
    },
    {
      name: t("PRODUCT_NAME"),
      selector: (row) => row.ProductName,
      sortable: true,
      width: "200px",
    },
    {
      name: t("STORE_NAME"),
      selector: (row) => row.StoreName,
      sortable: true,
      width: "130px",
    },
    {
      name: t("QUANTITY"),
      selector: (row) => (row.noOfProd ? row.noOfProd : 1),
      sortable: true,
      width: "110px",
    },
    {
      name: t("TYPE"),
      selector: (row) =>
        row.order_type == "return" ? (
          <span className="badge bg-danger rounded-pill">{t("RETURN")}</span>
        ) : (
          <span className="badge bg-success rounded-pill">{t("ORDER")}</span>
        ),
      sortable: true,
      width: "100px",
    },
    {
      name: t("ACTUAL_PRICE"),
      selector: (row) => row.product_cost,
      sortable: true,
      width: "140px",
      cell: (row) => (
        <div className="reg text-end w-100 me-3">
          ${numberWithCommas(row.product_cost)}
        </div>
      ),
    },
    // {
    //   name: 'Import Price',
    //   selector: row => row.import_cost,
    //   sortable: true,
    //   width: "140px",
    //   cell: (row) => <div className='reg text-end w-100 me-3'>{row.import_cost}</div>,
    // },
    {
      name: t("SELLING_PRICE"),
      selector: (row) => row.sell_cost,
      sortable: true,
      width: "140px",
      cell: (row) => (
        <div className="reg text-end w-100 me-3">
          ${numberWithCommas(row.sell_cost)}
        </div>
      ),
    },
    {
      name: t("USER_PROFIT"),
      selector: (row) => row.profit,
      sortable: true,
      width: "140px",
      cell: (row) => (
        <div className="reg">
          $
          {row.order_type == "return"
            ? `-${numberWithCommas(row.profit)}`
            : numberWithCommas(row?.profit)}
        </div>
      ),
    },
  ];

  const CustomNoDataMessage = () => (
    <div className="text-center py-5 my-5">
      <p> {t("ORDERS_NOT_FOUND")}</p>
    </div>
  );

  const handlePerRowsChange = async (newPerPage, page) => {
    let filterData = { ...filter, ...{ page: page, limit: newPerPage } };
    setFilter(filterData);
  };
  const handlePageChange = (page) => {
    let filterData = { ...filter, ...{ page: page } };
    setFilter(filterData);
  };

  const handleSearch = async (event) => {
    let filterData = { ...filter, ...{ search: event.target.value } };
    setFilter(filterData);
  };

  useEffect(() => {
    handleStartChange(startDate);
  }, [startDate]);

  useEffect(() => {
    handleEndChange(endDate);
  }, [endDate]);

  const handleStartChange = (date) => {
    let newDate = new Date(date);
    newDate.setMilliseconds(0);

    let filterData = { ...filter, ...{ startdate: newDate } };
    setFilter(filterData);
  };

  const handleEndChange = (date) => {
    let newDate = new Date(date);
    newDate.setMilliseconds(0);
    let filterData = { ...filter, ...{ enddate: newDate } };
    setFilter(filterData);
  };

  useMemo(() => {
    getOrder(filter);
  }, [filter, selectedValue]);

  const downloadData = (select) => {
    try {
      if (select == 1) {
        downloadCSV(record?.data, t("ORDER_LIST"));
      } else if (select == 2) {
        downloadXls(record?.data, t("ORDER_LIST"));
      } else if (select == 3) {
        downloadpdf(record?.data, t("ORDER_LIST"));
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleSelect = async (selectedItem) => {
    setSelectedValue(JSON.parse(selectedItem));
  };

  const numberWithCommas = (number) => {
    return number.toLocaleString();
  };

  const customPaginationOptions = {
    rowsPerPageText: t("ROWS_PER_PAGE"),
  };

  return (
    <div className="page_wrapper">
      <ScrollToTopOnMount />

      <Sidebar navLink={"/orders"} />
      <div className="w-100">
        <Navbarinner />
        <div className="right_wrapper">
          <h2 className="h3tag bdr mb-3">{t("ORDERS")}</h2>
          <div className="categories">
            <div className="hflx mt-3">
              <div className="flx gap-3">
                <div className="input_box">
                  <div class="input-group">
                    <input
                      type="text"
                      className="form-control bg-transparent border-end-0"
                      name="text"
                      placeholder={t("SEARCH_PRODUCT")}
                      onChange={handleSearch}
                    />
                    <span
                      class="input-group-text cursor-pointer bg-transparent"
                      id="basic-addon2"
                    >
                      <img src={search_icon} className="img-fluid" />
                    </span>
                  </div>
                </div>

                <div className="custom_date">
                  <DatePicker
                    selectsRange={true}
                    startDate={startDate}
                    endDate={endDate}
                    onChange={(update) => {
                      setDateRange(update);
                    }}
                    onCalendarOpen={() => {
                      setdrpdwn(true);
                    }}
                    onCalendarClose={() => {
                      setdrpdwn(false);
                    }}
                    customInput={<CustomInput />}
                  />
                </div>
                <div className="drp_dwn drp_dwn_hgt mb-6">
                  <Dropdown onSelect={handleSelect}>
                    <Dropdown.Toggle className="d-flex justify-content-between">
                      {selectedValue != "all"
                        ? ` ${
                            selectedValue?.store_name.charAt(0).toUpperCase() +
                            selectedValue?.store_name.slice(1)
                          }-${selectedValue?.default_storeid?.Store_name}`
                        : t("ALL")}
                    </Dropdown.Toggle>

                    <Dropdown.Menu className="w-100">
                      <Dropdown.Item eventKey={JSON.stringify("all")}>
                        {t("ALL")}
                      </Dropdown.Item>

                      {userstore &&
                        userstore.map((item) => {
                          // console.log(item, "kkk")
                          return (
                            <Dropdown.Item eventKey={JSON.stringify(item)}>
                              {item?.store_name?.charAt(0).toUpperCase() +
                                item?.store_name?.slice(1)}
                              -{item?.default_storeid?.Store_name}
                            </Dropdown.Item>
                          );
                        })}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
              {User.vip_status && (
                <div className="flx gap-2 flex-row">
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={() => downloadData(3)}
                  >
                    <img src={pdf} class="img-fluid" alt="img" />
                  </div>
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={() => downloadData(2)}
                  >
                    <img src={xlsx} class="img-fluid" alt="img" />
                  </div>
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={() => downloadData(1)}
                  >
                    <img src={csv} class="img-fluid" alt="img" />
                  </div>
                </div>
              )}
            </div>
            <Scrollbars style={{ width: "100%", height: 720 }}>
              <div className="custom_table_chg">
                {/* <DataTable
                  columns={columns}
                  data={orderData}
                  selectableRows
                  noDataComponent={<CustomNoDataMessage />}
                  sortIcon={<img src={sort_arw} className="img-fluid ms-2 " alt="logo" />}
                  pagination
                /> */}

                <DataTable
                  columns={columns}
                  data={record?.data}
                  paginationTotalRows={record?.count}
                  responsive
                  sortIcon={
                    <img
                      src={sort_arw}
                      className="img-fluid ms-2 "
                      alt="logo"
                    />
                  }
                  noDataComponent={<CustomNoDataMessage />}
                  pagination
                  paginationRowsPerPageOptions={[5, 10, 15, 20, 100, 1000]}
                  paginationServer
                  onChangeRowsPerPage={handlePerRowsChange}
                  onChangePage={handlePageChange}
                  paginationComponentOptions={customPaginationOptions}
                />
              </div>
            </Scrollbars>
          </div>
        </div>
      </div>
    </div>
  );
}
