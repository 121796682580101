import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import CurrencyFormat from "react-currency-format";
import { useTranslation } from "react-i18next";

//import api
import { transferCurrency } from "../../action/walletAction";

//import lib
import isEmpty from "../../lib/isEmpty";
import { toFixedDown, truncateDecimals } from "../../lib/roundOf";
import { toastAlert, textCopy } from "../../utils/toast";

//import validation
import { Validation } from "./validation";

const initialFormValue = {
  fromCurrencyId: "",
  toCurrencyId: "",
  amount: 0,
};

const ExchangeModal = (props) => {
  //props
  const { isShow, record, onHide } = props;

  //redux
  const currency = useSelector((state) => state.currency);
  const walletData = useSelector((state) => state.wallet);
  const priceConversion = useSelector((state) => state.priceconversion);

  //hooks
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  //state
  const [options, setOption] = useState([]);
  const [loader, setLoader] = useState(false);
  const [errors, setErrors] = useState({});
  const [formValue, setFormValue] = useState(initialFormValue);
  const { fromCurrencyId, toCurrencyId, amount } = formValue;
  const [convertAmount, setConAmount] = useState(0);
  const [toCurrency, settoCurrency] = useState("");

  //function
  const handleClick = () => {
    let formData = {
      ...formValue,
      ...{ ["amount"]: toFixedDown(record?.balance, 8) },
    };
    setFormValue(formData);
  };

  const hanldeClose = () => {
    onHide();
    setErrors({});
    setFormValue(initialFormValue);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    let formData = { ...formValue, ...{ [name]: value } };
    setFormValue(formData);
    if (!isEmpty(errors)) {
      setErrors({});
    }
  };

  const handleAmount = (e) => {
    const { name, value } = e.target;
    if (/[^0-9.]/.test(value)) return;
    let formData = { ...formValue, ...{ [name]: toFixedDown(value, 8) } };
    setFormValue(formData);
    if (!isEmpty(errors)) {
      setErrors({});
    }
  };

  const fetchData = () => {
    let array = [];
    walletData &&
      walletData.length > 0 &&
      walletData.map((item, key) => {
        let findata = currency && currency.find((el) => el._id == item._id);
        // console.log(findata, 'item')
        array.push({
          value: findata?._id,
          label: findata?.currencySymbol,
          image: findata?.image,
        });
      });
    setOption(array);
  };

  const handleAsset = () => {
    let fromCurrency = record?.coin == "USDT" ? "USD" : record?.coin;
    let toCurrency =
      currency &&
      currency.find((el) => el._id.toString() == toCurrencyId.toString());
    let convertSymbol =
      toCurrency?.currencySymbol == "USDT" ? "USD" : toCurrency?.currencySymbol;
    settoCurrency(toCurrency?.currencySymbol);
    let priceCNV;
    if (priceConversion && priceConversion.length > 0) {
      priceCNV = priceConversion.find(
        (el) =>
          (el.baseSymbol == fromCurrency &&
            el.convertSymbol == convertSymbol) ||
          (el.baseSymbol == convertSymbol && el.convertSymbol == fromCurrency)
      );
    }
    // console.log(priceCNV, 'price/CNV')
    // console.log(convertSymbol, 'convertSymbol', record?.coin)
    let conPrice =
      convertSymbol != priceCNV?.convertSymbol
        ? 1 / priceCNV?.convertPrice
        : priceCNV?.convertPrice;
    // console.log(conPrice, 'conPrice')
    setConAmount(conPrice * amount);
  };

  const handleSubmit = async () => {
    try {
      setLoader(true);
      let reqData = {
        fromCurrencyId: record?._id,
        toCurrencyId: toCurrencyId,
        amount: amount,
      };
      let validationError = Validation(reqData);
      if (!isEmpty(validationError)) {
        setErrors(validationError);
        setLoader(false);
        return;
      }
      const { status, error, loading, message } = await transferCurrency(
        reqData,
        dispatch
      );
      setLoader(loading);
      if (status == "success") {
        setLoader(false);
        hanldeClose();
        toastAlert("success", t(message), "login");
      } else {
        setLoader(false);
        if (error) {
          setErrors(error);
        }
        toastAlert("error", t(message), "login");
      }
    } catch (err) {
      setLoader(false);
      console.log(err, "error");
    }
  };

  useEffect(() => {
    if (isShow) {
      fetchData();
    }
  }, [walletData, isShow, currency, priceConversion]);

  useEffect(() => {
    handleAsset();
  }, [amount, toCurrencyId, record]);

  // console.log(record, 'record')
  let Balance = record?.balance;

  return (
    <>
      <Modal
        show={isShow}
        onHide={hanldeClose}
        backdrop="static"
        keyboard={false}
        size="md"
        className="custom_modal"
        centered
      >
        <Modal.Header closeButton onClick={hanldeClose}>
          <Modal.Title>
            <h4 className="modal-title mt-0">{t("EXCHANGE")} </h4>{" "}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className="assets_form">
            <div className="mb-3">
              <div className="d-flex align-items-center justify-content-between">
                <label for="withdrawamount" className="form-label">
                  {t("FROM_CURRENCY")}
                </label>
                <label class="form-label">
                  {t("AVAILABLE_BALANCE")}:{" "}
                  <CurrencyFormat
                    displayType={"text"}
                    thousandSeparator={true}
                    value={truncateDecimals(Balance, record?.decimals)}
                  />{" "}
                  {record?.coin}
                </label>
              </div>
              <input
                type="text"
                class="form-control primary_asset_inp"
                placeholder="0.000000"
                name="fromCurrencyId"
                value={record?.coin}
                disabled
              />
            </div>
            <div className="mb-3">
              <div className="d-flex align-items-center justify-content-between">
                <label for="withdrawamount" className="form-label">
                  {t("TO_CURRENCY")}
                </label>
              </div>
              <select
                value={toCurrencyId}
                name="toCurrencyId"
                onChange={handleChange}
                className="form-select primary_asset_select"
              >
                <option value={""}>{t("SELECT_CURRENCY")}</option>
                {options &&
                  options.length > 0 &&
                  options.map((item, key) => {
                    if (record?.coin != item.label) {
                      return (
                        <option value={item.value} key={key}>
                          {item.label}
                        </option>
                      );
                    }
                  })}
              </select>
              <span className="text-danger">{t(errors?.toCurrencyId)}</span>
            </div>
            <div className="mb-3">
              <div className="d-flex align-items-center justify-content-between">
                <label for="withdrawamount" className="form-label">
                  {t("AMOUNT")}
                </label>
                <label className="max_label" onClick={handleClick}>
                  {t("MAX")}
                </label>
              </div>
              <div class="input-group input_border">
                <input
                  type="number"
                  class="form-control primary_asset_inp"
                  placeholder="0.000000"
                  name="amount"
                  value={amount}
                  onChange={handleAmount}
                />
                <span class="primary_asset_inp">{record?.coin}</span>
              </div>
              <div class="form-text ms-3">
                ≈ {toCurrency ? toCurrency : ""}{" "}
                <CurrencyFormat
                  displayType={"text"}
                  thousandSeparator={true}
                  value={truncateDecimals(convertAmount ? convertAmount : 0, 4)}
                />
              </div>
              <span className="text-danger">{t(errors?.amount)}</span>
            </div>
            <div className="asset_modal_btn_grp mt-4">
              <button
                type="button"
                className="w-100 primary_btn primary_btn_transform"
                onClick={hanldeClose}
              >
                {t("CANCEL")}
              </button>
              <button
                type="button"
                onClick={handleSubmit}
                className="w-100 mt-4 primary_btn primary_btn_with_bg primary_btn_transform"
              >
                {loader && <i class="fas fa-spinner fa-spin"></i>}{" "}
                {t("CONFIRM")}
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ExchangeModal;
