import { React, useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { Modal, Dropdown } from "react-bootstrap";
import { Scrollbars } from "react-custom-scrollbars-2";
// img
import logo from "../assets/images/logo.png";
import edit from "../assets/images/edit.svg";
import marketplace from "../assets/images/marketplace.svg";
import dashboard from "../assets/images/dashboard.svg";
import orders from "../assets/images/orders.svg";
import products from "../assets/images/products.svg";
import support from "../assets/images/support.svg";
import setting from "../assets/images/user.svg";
import balance1 from "../assets/images/balance1.svg";
import balance from "../assets/images/Removal-828.png";
import w1 from "../assets/images/balance1.svg"

// import menu from "../assets/images/menu.svg";
import menu from "../assets/images/msg1176172984-45844-removebg-preview.png";

import search_icon from "../assets/images/search_icon.svg";
import tag1 from "../assets/images/tag1.svg";
import box from "../assets/images/box.svg";
import question from "../assets/images/question.svg";
import dot from "../assets/images/dot.svg";

import subcrib from "../assets/images/subcrib.svg";

import AddStorePop from "../components/addstore/AddStorePop";
import { useDispatch, useSelector } from "react-redux";

import DisplayStorePop from "../components/displayStore/DisplayStorePop";
import { getPlanData } from "../action/user.action";
import { useTranslation } from "react-i18next";
import { isEmpty } from "../helper/validation";

export default function Sidebar({ navLink }) {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);

  const [state, setstate] = useState(screenWidth < 1199 ? false : true);
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [selectedValue, setSelectedValue] = useState(null);
  const { t } = useTranslation()

  const [isOpen, setIsOpen] = useState(false);

  const [PlanColor, setPlancolor] = useState("");

  useEffect(() => {
    fetchPlanData();
  }, [PlanColor]);

  const fetchPlanData = async () => {
    try {
      const payload = {
        plan_id: user.plan_id,
      };
      const { status, result } = await getPlanData(payload);

      // console.log(result, 'SAKTHIBALA 11111');
      if (status) {
        setPlancolor(result?.color);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const user = useSelector((state) => state.account);

  const handleSelect = (selectedItem) => {
    setSelectedValue(selectedItem);
  };

  const toggleDropdown = () => {
    // console.log(isOpen, "isOpen");
    setIsOpen(isOpen ? false : true);
  };

  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  if (state) {
    document.body.classList.add("custom-body-class");
  } else {
    document.body.classList.remove("custom-body-class");
  }

  const componentRef = useRef(null);

  // Close the component when clicking outside
  const handleClickOutside = (event) => {
    if (
      componentRef.current &&
      !componentRef.current.contains(event.target) &&
      window.innerWidth < 1199
    ) {
      setstate(false);
    }
  };

  useEffect(() => {
    // Attach or detach click event listener based on screen width
    if (screenWidth < 1199) {
      document.addEventListener("click", handleClickOutside);
    } else {
      document.removeEventListener("click", handleClickOutside);
    }

    // Clean
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [screenWidth]);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
      setWindowHeight(window.innerHeight);
    };

    // Add resize event listener
    window.addEventListener("resize", handleResize);

    return () => {
      // Remove resize event listener on cleanup
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // console.log(navLink, "navLink");
  const numberWithCommas = (number) => {
    return number.toLocaleString();
  };

  const [totalUSD, setTotalUSD] = useState(0);

  const currency = useSelector((state) => state.currency);
  const walletData = useSelector((state) => state.wallet);
  const priceConversion = useSelector((state) => state.priceconversion);
  const defaultcurrency = "USD";

  const handleAsset = () => {
    try {
      let totalAmount = 0;
      let tempArr = [...walletData];
      currency?.length >= 0 &&
        currency.map((item, index) => {
          let PriceCnv = priceConversion.find(
            (el) =>
              el.baseSymbol == item.currencySymbol && el.convertSymbol == "USD"
          );
          let pairIndex =
            tempArr &&
            tempArr.findIndex((el) => {
              return el._id == item._id;
            });
          if (pairIndex >= 0 && !isEmpty(pairIndex)) {
            let Balance = tempArr[pairIndex].balance;
            tempArr[pairIndex] = {
              ...tempArr[pairIndex],
              ...{
                USDValue: !isEmpty(PriceCnv?.convertPrice)
                  ? parseFloat(Balance * PriceCnv.convertPrice)
                  : Balance,
              },
            };
            totalAmount += tempArr[pairIndex].USDValue;
          }
        });

      if (defaultcurrency == "USD") {
        setTotalUSD(totalAmount);
      }
    } catch (err) {
      console.log("err:------ ", err);
    }
  };

  useEffect(() => {
    if (
      !isEmpty(walletData) &&
      !isEmpty(currency) &&
      !isEmpty(priceConversion)
    ) {
      handleAsset();
    }
  }, [walletData, currency, priceConversion, defaultcurrency]);

  return (
    <>
      <div
        className={`sidebar ${state ? "open" : "close"} `}
        ref={componentRef}
      >
        <div className="overflow-hidden">
          <Link className="navbar-brand d-block" to="/">
            <img src={logo} className="logo_inner" alt="logo" />
          </Link>
        </div>

        <div className="menu_icon_relative">
          <div
            onClick={() => {
              setstate(!state);
            }}
            className="menu_icon"
          >
            <img
              src={menu}
              class="img-fluid"
              alt="img"
              style={{ width: "55px", transform: "rotate(90deg)" }}
            />
          </div>
        </div>
        <div className="scrll">
          <Scrollbars
            style={{ width: "1005", height: windowHeight - 100 }}
            //  autoHide
            //  autoHideTimeout={450}
            //  autoHideDuration={0}
          >
            <div className="overflow-hidden">
              <div className="name cursor-pointer" onClick={handleShow}>
                {/* <div className="round">{user?.userName[0]?.toUpperCase()}</div> */}
                <div className="flx">
                  <span>{t("MYSTORE")}</span>
                  <img src={edit} className="img-fluid" alt="img" />
                </div>
              </div>
            </div>

            <ul className="menus">
              <li className="d-xl-none mem mb-3">
                <div data-bs-dismiss="offcanvas" aria-label="Close">
                  <div className={`box`}>
                    {user.plan_type == "none" ? (
                      <a
                        href="/Packagesolution"
                        style={{ textDecoration: "none" }}
                        className="primary_btn market_btn"
                      >
                        Subscription Package
                      </a>
                    ) : (
                      <a
                        href="/Packagesolution"
                        style={{ textDecoration: "none" }}
                        className={`primary_btn market_btn abv_box ${PlanColor} `}
                      >
                        <span> {user.plan_type} Package</span>{" "}
                      </a>
                    )}
                  </div>
                  {/* PlanColor */}
                </div>
              </li>
              <li className="d-xl-none mem mb-3">
                {/* <button className="primary_btn market_btn"> */}
                {/* <a href="/marketing-package">
              Marketing package:<span> $ 600</span>
            </a> */}
                {/* </button> */}

                <div data-bs-dismiss="offcanvas" aria-label="Close">
                  <button
                    className="primary_btn deposit"
                    data-bs-toggle="modal"
                    data-bs-target="#deposit_modal"
                  >
                    {t("DEPOSIT")}
                  </button>
                </div>
              </li>

              {/* <li className="d-xl-none">
            {user.kycstatus == 'approved' ? <>{

              user.deposit_Balance == 0 ? <>

                <button className="primary_btn deposit"
                  style={{ opacity: "50%" }}
                  disabled
                >
                  Withdraw
                </button>
              </> :
                user.widthDrawStatus == "Withdraw" ? (
                  <button className="primary_btn deposit" data-bs-toggle="modal"
                    data-bs-target="#WithDraw_modal">
                    {user.widthDrawStatus}
                  </button>
                ) : user.widthDrawStatus == "Pending" ? (
                  <button className="primary_btn deposit">
                    {user.widthDrawStatus}...
                  </button>
                ) : (
                  ""
                )
            }</> :
              <>
                <button className="primary_btn deposit"
                  style={{ opacity: "50%" }}
                  disabled
                >
                  Withdraw
                </button>
              </>
            }
          </li> */}
              <div className="d-xl-none balance">
                <img src={balance} style={{ width: "45px" }} alt="img" />
                <span>
                  {t("BALANCE")}: $ {numberWithCommas(totalUSD) || 0}
                </span>
              </div>
              {/* <li className="d-xl-none">
            <Link to="#">
              <img src={balance1} class="img-fluid" alt="img" />
              <span>Balance: $ 3400.47</span>
            </Link>
          </li> */}
              <li>
                <Link
                  to="/dashboard"
                  className={`${navLink == "/dashboard" ? "activee" : ""}`}
                >
                  <img src={dashboard} className="img-fluid" alt="logo" />
                  <span>{t("DASHBOARD")}</span>
                </Link>
              </li>
              <li>
                <Link
                  to="/wallet"
                  className={`${navLink == "/wallet" ? "activee" : ""}`}
                >
                  <img src={w1} className="img-fluid" alt="logo" />
                  <span>{t("WALLET")}</span>
                </Link>
              </li>
              <li>
                <Link
                  to="/marketplace"
                  className={`${navLink == "/marketplace" ? "activee" : ""}`}
                >
                  <img src={marketplace} className="img-fluid" alt="logo" />
                  <span>{t("MARKETPLACE")}</span>
                </Link>
              </li>

              <li>
                <Link
                  to="/vip-marketplace"
                  className={`${
                    navLink == "/vip-marketplace" ? "activee" : ""
                  }`}
                >
                  <img src={marketplace} className="img-fluid" alt="logo" />
                  <span>{t("VIP_MARKETPLACE")}</span>
                </Link>
              </li>

              <li>
                <Link
                  to="/store-marketplace"
                  className={`${
                    navLink == "/store-marketplace" ? "activee" : ""
                  }`}
                >
                  <img src={marketplace} className="img-fluid" alt="logo" />
                  <span>{t("STORE_MARKETPLACE")}</span>
                </Link>
              </li>
              <li>
                <Link
                  to="/orders"
                  className={`${navLink == "/orders" ? "activee" : ""}`}
                >
                  <img src={orders} className="img-fluid" alt="logo" />
                  <span>{t("ORDERS")}</span>
                </Link>
              </li>
              {/* <li>
            <Link to="/deposits" className={`${navLink == "/deposits" ? "activee" : ""}`}>
              <img src={orders} className="img-fluid" alt="logo" />
              <span>Deposit</span>
            </Link>
          </li>
          <li>
            <Link to="/withdraws" className={`${navLink == "/withdraws" ? "activee" : ""}`}>
              <img src={orders} className="img-fluid" alt="logo" />
              <span>Withdraw</span>
            </Link>
          </li> */}

              <li>
                <Link
                  to="/finance"
                  className={`${navLink == "/finance" ? "activee" : ""}`}
                >
                  <img src={orders} className="img-fluid" alt="logo" />
                  <span>{t("FINANCE")}</span>
                </Link>
              </li>

              <li>
                <Link
                  to="/myproduct"
                  className={`${navLink == "/myproduct" ? "activee" : ""}`}
                >
                  <img src={products} className="img-fluid" alt="logo" />
                  <span>{t("MY_INVENTORY")}</span>
                </Link>
              </li>

              <li>
                <Link
                  to="/Packagesolution"
                  className={`${
                    navLink == "/Packagesolutione" ? "activee" : ""
                  }`}
                >
                  <img src={subcrib} className="img-fluid" alt="logo" />
                  <span>{t("PACKAGE_SOLUTIONS")}</span>
                </Link>
              </li>

              {/* <li>
            <Link to="/advertisement-package" className={`${navLink == "/advertisement-package" ? "activee" : ""}`}>
              <img src={subcrib} className="img-fluid" alt="logo" />
              <span>Advertisement Package
              </span>
            </Link>
          </li>

          <li>
            <Link to="/shipping-package" className={`${navLink == "/shipping-package" ? "activee" : ""}`}>
              <img src={subcrib} className="img-fluid" alt="logo" />
              <span>Shipping Package
              </span>
            </Link>
          </li>

          <li>
            <Link to="#" onClick={toggleDropdown}>
              <img src={subcrib} className="img-fluid" alt="logo" />
              <span>Packages Solutions</span>
            </Link>
            {isOpen && (
              <ul>
                <li>
                  <Link to="/marketing-package" className={`${navLink == "/marketing-package" ? "activee" : ""}`}>
                    Marketing Packages
                  </Link>
                </li>
                <li>
                  <Link to="/advertisement-package" className={`${navLink == "/advertisement-package" ? "activee" : ""}`}>
                    Advertisement Packages
                  </Link>
                </li>
                <li>
                  <Link to="/shipping-package" className={`${navLink == "/shipping-package" ? "activee" : ""}`}>
                    Shipping Packages
                  </Link>
                </li>
              </ul>
            )}
          </li> */}

              <li>
                <Link
                  to="/support-ticket"
                  className={`${navLink == "/support-ticket" ? "activee" : ""}`}
                >
                  <img src={support} className="img-fluid" alt="logo" />
                  <span>{t("CUSTOMER_SUPPORT")}</span>
                </Link>
              </li>

              <li>
                <Link
                  to="/referral"
                  className={`${navLink == "/referral" ? "activee" : ""}`}
                >
                  <img src={setting} className="img-fluid" alt="logo" />
                  <span>{t("REFER")}</span>
                </Link>
              </li>
            </ul>
          </Scrollbars>
        </div>
      </div>

      <DisplayStorePop
        show={show}
        handleClose={handleClose}
        handleShow1={handleShow1}
      />

      <AddStorePop
        show1={show1}
        setShow1={setShow1}
        handleClose1={handleClose1}
      />
    </>
  );
}
