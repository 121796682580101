import React, { useEffect, useState } from "react";
import { InputGroup, Form, Accordion } from "react-bootstrap";
import { Modal, Dropdown } from "react-bootstrap";
import { getSupportCategory, createticket } from "../../action/user.action";
import { toastAlert } from "../../utils/toast";
import { useTranslation } from "react-i18next";

function SuportCreate() {
  const [allCat, setallCat] = useState([]);
  const [selectedValue, setSelectedValue] = useState(null);
  const [message1, setmessage] = useState("");
  const [file, setfile] = useState("");
  const [validateError, setValidateError] = useState({});

  const { t } = useTranslation();

  const handleSelect = (selectedItem) => {
    // console.log(JSON.parse(selectedItem), "selectedItem");
    setSelectedValue(JSON.parse(selectedItem));
  };

  useEffect(() => {
    getCategory();
  }, []);

  const getCategory = async () => {
    try {
      var { status, result } = await getSupportCategory();
      if (status) {
        setallCat(result);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handlefilechanges = ({ target: { files } }) => {
    if (!files.length) {
      return;
    }

    let picture = files[0];
    if (!picture.type.toLowerCase().startsWith("image/")) {
      throw Error(t("INVALID_IMAGE"));
    }
    setfile(picture);
    // let formData = { ...formdata, ...{ file: picture } };
    // setformdata(formData);
  };

  const Fromvalidation = async () => {
    try {
      var validateError = {};
      if (selectedValue == null) {
        validateError.category = "CATEGORY_REQUIRED";
      }
      if (message1.trim() == "") {
        validateError.description = "MESSAGE_REQUIRED";
      }

      setValidateError(validateError);
      return validateError;
    } catch (err) {
      //console.log(err);
    }
  };

  const handelsumbit = async () => {
    try {
      const check = await Fromvalidation();
      var errorsSize = Object.keys(check).length;
      if (errorsSize == 0) {
        let Formdata = new FormData();
        Formdata.append("categoryId", selectedValue?._id);
        Formdata.append("message", message1);
        Formdata.append("image", file);

        const { status, message } = await createticket(Formdata);

        if (status == true) {
          toastAlert("success", t(message));
          setTimeout(() => {
            window.location.reload();
          }, 1500);
        } else {
          toastAlert("error", t("SUPPORT_ERROR"));
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="custom_faq mb-4 p-4">
      <h5 className="mb-3">{t("CREATE_SUPPORT_TICKET")}</h5>
      <div className="input_box mb-4">
        {/* <label className="darkgrey mb-2" >Name</label>
                <InputGroup>
                    <Form.Control
                        aria-label="amount"
                        aria-describedby="basic-addon1"
                        placeholder="Name"
                    />
                </InputGroup> */}
      </div>
      <div className="input_box mb-4">
        <label className="sem sm_txt mb-3">{t("CATEGORY")}</label>
        <div className="drp_dwn drp_dwn_hgt mb-4">
          <Dropdown onSelect={handleSelect}>
            <Dropdown.Toggle className="d-flex justify-content-between">
              {selectedValue
                ? ` ${selectedValue?.categoryName}`
                : t("SELECT_CATEGORY")}
            </Dropdown.Toggle>

            <Dropdown.Menu className="w-100">
              {allCat &&
                allCat.map((item) => (
                  <Dropdown.Item eventKey={JSON.stringify(item)}>
                    {item?.categoryName}
                  </Dropdown.Item>
                ))}
            </Dropdown.Menu>
          </Dropdown>
        </div>
        {validateError.category && (
          <span className="text-danger">{t(validateError.category)}</span>
        )}
      </div>
      <div className="input_box mb-4">
        <label className="darkgrey mb-2">{t("MESSAGE")}</label>
        <InputGroup>
          <Form.Control
            as="textarea"
            aria-label="amount"
            aria-describedby="basic-addon1"
            placeholder={t("MESSAGE")}
            className="rounded-5"
            style={{ height: "150px", resize: "none" }}
            onChange={(e) => setmessage(e.target.value)}
          />
        </InputGroup>
        {validateError.description && (
          <span className="text-danger">{t(validateError.description)}</span>
        )}
      </div>
      <div>
        <Form.Group
          //  controlId="formFile"
          className="mb-3"
        >
          <Form.Label className="bg-transparent">
            <span>
              {t("UPLOAD_FILE")}
              {/* Attachment  (Add fil or Drop files here) */}
            </span>
          </Form.Label>
          <Form.Control
            type="file"
            onChange={handlefilechanges}
            accept="image/png, image/jpeg"
          />
        </Form.Group>
        <p>
          {t("UPLOAD_SIZE_RESTRICT")}
          {/* Note: MAX 1 MB (only .jpeg, .png, .jpg, .pdf) */}
        </p>

        <p style={{ color: "blue" }}>{file.name}</p>
      </div>

      <div className="blue_btn">
        <button
          variant="contained"
          className="w-auto primary_btn"
          onClick={handelsumbit}
        >
          {" "}
          {t("SUBMIT")}
        </button>
      </div>
    </div>
  );
}

export default SuportCreate;
